<template>
  <div id="brand">
    <div class="hadheight"></div>
    <Header @b2listwt="getBrandList" />
    <div class="weeb_load basefont40" style="padding: 1.6rem 0"  v-if="error==0">加载中....</div>
    <div class="brand-sec1 productbox-sec1">
        
        <template v-if="error==1">
            <a href="javascript:;"
            @click="brandlist(item.id,item.title)" 
            v-for="(item,index) in productdata"
            :key="index"
            class="item"
            >
                <div class="img">
                    <img :src="item.url" :alt="item.title">
                </div>
                <div class="font">
                    <div class="tit basefont36">
                        <span>{{item.title}}</span>
                    </div>
                </div>
            </a>
        </template>
        <template v-if="error==2">
            <div class="item on">
                <div class="img">
                    <img src="../../assets/images/b2ppingbeimgf5.jpg" :alt="暂未开放">
                </div>
                <!-- <div class="font">
                    <div class="tit basefont36">
                        <span>{{item.title}}</span>
                    </div>
                </div> -->
                <div class="kaif">暂未开放</div>
                <div class="daixu">To Be Continued</div>
            </div> 
            <div class="item on2">
                <div class="img">
                    <img src="../../assets/images/b2ppingbeimgf6.jpg" alt="To Be Continued">
                </div>
                <div class="daixu">To Be Continued</div>
            </div>
        </template>
        
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
// import ajax from 'axios'; 

export default {
  name: "Brand",
  components: {
    Header,
    Footer
  },
  data(){
    return {
      biaozhi: "item",
      productdata:"",
      error: 0
    }
  },

  created:function () {
      
  },
  mounted:function () {
      
  },
  computed:{
      
  },
  methods: {
    brandlist(id,title){
        // console.log(1);
        if(id || title){
            this.$router.push({name: 'Brandlist',query:{title:title}});
        }

        
        // var userName = $("input[name='sysWfBusinessForm.fdDraftorName']").val();  //用户名
        // $(".tb_normal > tbody > tr > td[column='3']").each(function(){
        //     var thatName = $(this).find(".inputselectsglreadonly .input input[readonly]").val();  //当前用户名
        //     console.log($(this).parents("tr[type='templateRow']").find(".xform_textArea textarea").length);
        //      $(this).parents("tr[type='templateRow']").find(".xform_textArea textarea").prop('readonly', false);
        //     if(thatName==userName){  //用户名相等就启用
        //         $(this).parents("tr[type='templateRow']").find(".xform_textArea textarea").prop('disabled', false);
        //     }else{
        //          $(this).parents("tr[type='templateRow']").find(".xform_textArea textarea").prop('disabled', true);
        //     }
        // });
    },
    jisuan(value){
        if(value=="1"){
            return "item on"
        }else if(value=="2"){
            return "item on2"
        }else{
            return "item"
        }

    },
    getBrandList(_val){ //获取产品列表数据
        // this.productdata = _val;
        // _val.
        if(_val.attrValueList.length!=0){
            let obj = _val.attrValueList.map( (v, index) => {
                return {title:v,url: _val.imageList[index]};
            } ); 
            this.productdata = obj;
            this.error=1;
        }else{
            this.error=2;
        }
    }
  }
  
}
</script>
